import {AccessLevel, ChatV4Response} from '../common-lib/src/api/Api.types';
import {isLocalEnv} from '../common-lib/src/common/utils';
import {checkIsAgreed} from '../components/CookieBar/utils';
import {isDevb2c} from '../utils';
import {webAmpliApi} from './webAmplitude';

type ChatParams = {
	bot_name: string;
	split_response?: string;
	split_smart_replies?: string;
	chat_id: number;
	chat_type: 'default' | 'custom_shared' | 'custom';
	access_level: AccessLevel;
};

type CategoryParams = {
	category_id: number | string;
	category_name: string;
};

export type ChatVoteParams = {
	is_photo: boolean;
} & ChatParams;

type BotCreateParams = {
	chat_id: string;
	user_id: string;
	is_private: boolean;
	idle_available: boolean;
	idle_turned_on: boolean;
};

export interface WebEventsProvider {
	init(): void;
	setUserProps(data: Record<string, any>): void;
	event(event: string, data: any): void;
	setUserSplit(split: string): void;
	setUserId(userId: string): void;
	providerId: string;
	optOut(): void;
}

class WebEventsApi {
	isB2B?: boolean;
	isOn: boolean;
	split: string | null;
	providers: WebEventsProvider[];
	minTimeBetweenSession = 5 * 60;

	constructor(providers: WebEventsProvider[]) {
		this.isOn = !isLocalEnv() && !isDevb2c();
		this.providers = providers;
		this.init();
		this.split = null;
	}

	setMinTimeBetweenSession(minTimeBetweenSession: number) {
		this.minTimeBetweenSession = minTimeBetweenSession * 60;
	}

	logSession() {
		const lastSession = localStorage.getItem('lastSession');
		const now = new Date().getTime();

		if (
			!lastSession ||
			now - +lastSession > this.minTimeBetweenSession * 1000
		) {
			this.startSession();
			localStorage.setItem('lastSession', now.toString());
		}
	}

	startSession() {
		this.event('start_session', {}, ['Amplitude']);
	}

	private init() {
		if (!this.isOn) {
			return;
		}

		this.providers.forEach((provider) => provider.init());
		this.setUserProps();
	}

	setUserProps() {
		if (!this.isOn) {
			return;
		}

		const data = {
			Platform: 'Web',
			web_platform: 'b2c_web_app',
		};

		this.providers.forEach((provider) => provider.setUserProps(data));
	}

	setUserSplit(split: string) {
		if (!this.isOn || !split) {
			return;
		}

		if (this.split === split) {
			return;
		}

		this.split = split;

		this.providers.forEach((provider) => provider.setUserSplit(split));
	}

	setUserId(userId: string) {
		if (!this.isOn) {
			return;
		}

		this.providers.forEach((provider) => provider.setUserId(userId));
	}

	message(
		params: ChatParams & {
			message_type: 'common' | 'sexting';
			is_photo: boolean;
			user_messages: number;
			chat_session_messages: number;
			response_time: number;
			contains_photo_request: boolean;
			blured: boolean;
			media_type: null | 'photo' | 'video';
			ultra_llm_id: null | string;
			ultra_llm_name: null | string;
			analytics_metadata: ChatV4Response['analytics_metadata'];
		}
	) {
		const data = {
			...params,
			talking_head: false,
			source: 'user',
		};

		if (data.split_response) {
			this.setUserSplit(data.split_response);
		}

		this.event('Message_by_user', data);
	}

	messageEdited(params: ChatParams) {
		this.event('message_edited', params);
	}

	messageDeleted(params: ChatParams) {
		this.event('message_deleted', params);
	}

	botSentGreeting(params: ChatParams & {is_photo: boolean}) {
		this.event('bot_sent_greeting', params);
	}

	chatAppear(params: Pick<ChatParams, 'bot_name' | 'chat_id' | 'chat_type'>) {
		this.event('chat_appear', params);
	}

	historyDeleted(params: ChatParams) {
		this.event('history_deleted', params);
	}

	chatDeleted(params: ChatParams) {
		this.event('chat_deleted', params);
	}

	searchAppear() {
		this.event('search_appear', {});
	}

	searchBack(query: string) {
		this.event('search_back', {query});
	}

	searchBotTapped(bot_index: number, bot_name: string, query: string) {
		this.event('search_bot_tapped', {bot_index, bot_name});
	}

	searchEmptyResult(query: string) {
		this.event('search_empty_result', {query});
	}

	categoryAppear(params: CategoryParams) {
		this.event('category_details_appear', params);
	}

	categoryDetailsBack(params: CategoryParams) {
		this.event('category_details_back', params);
	}

	categoryDetailsBotTapped(
		params: CategoryParams & {bot_index: string; bot_name: string}
	) {
		this.event('category_details_bot_tapped', params);
	}

	categoryDetailsLoaded(params: CategoryParams & {new_count: number}) {
		this.event('category_details_loaded', params);
	}

	exploreAppear() {
		this.event('explore_appear', {});
	}

	exploreBotTapped(
		params: CategoryParams & {
			bot_index: string;
			bot_name: string;
			bot_chat_id: number;
			category_index: number;
		}
	) {
		this.event('explore_bot_tapped', params);
	}

	messageUpvoted(params: ChatVoteParams) {
		this.event('message_upvoted', params);
	}

	messageDownvoted(params: ChatVoteParams) {
		this.event('message_downvoted', params);
	}

	messageBetter(params: ChatVoteParams) {
		this.event('message_better', params);
	}

	messageWorse(params: ChatVoteParams) {
		this.event('message_worse', params);
	}

	messageSame(params: ChatVoteParams) {
		this.event('message_same', params);
	}

	responseWasRegenerated(params: ChatVoteParams) {
		this.event('response_was_regenerated', params);
	}

	magicPhotoRequested(params: ChatParams) {
		this.event('magic_photo_requested', params);
	}

	magicPhotoGenerated(params: ChatParams & {generation_time: number}) {
		this.event('magic_photo_generated', params);
	}

	newUser() {
		this.event('new_user', {}, ['Amplitude']);
	}

	customBotCreated(params: BotCreateParams) {
		this.event('Custom_bot_created', params);
	}

	profileAppear() {
		this.event('profile_appear', {});
	}

	publicProfileAppear(data: {is_current_user: boolean}) {
		this.event('public_profile_appear', data);
	}

	optOut() {
		this.providers.forEach((provider) => provider.optOut());
	}

	paywallAppear() {
		this.event('paywall_appear', {});
	}

	paywallCloseTapped() {
		this.event('paywall_close_tapped', {});
	}

	paywallClosed() {
		this.event('paywall_closed', {});
	}

	paywallLoadingProductsFailed(params: {error?: string; error_code?: number}) {
		this.event('paywall_loading_products_failed', params);
	}

	paywallProductSelected(params: {product_id: string; product_name: string; interval?: string}) {
		this.event('paywall_product_selected', params);
	}

	paywallPurchaseStarted(params: {product_id: string; product_name: string}) {
		this.event('paywall_purchase_started', params);
	}

	paywallPurchaseSuccess(params: {
		product_id: string;
		product_name: string;
		revenue: number;
	}) {
		this.event('paywall_purchase_success', params);
	}

	storeActionTapped(params: {type: 'photos' | 'videos'}) {
		this.event('store_action_tapped', params);
	}

	loginError(params: {device_id?: string; error: string; error_code?: number}) {
		this.event('login_error', params);
	}

	pairPhotoRequested(params: {is_persona_avatar_set: boolean}) {
		this.event('pair_photo_requested', params);
	}

	regenerationReverted(params: ChatVoteParams) {
		this.event('regeneration_reverted', params);
	}

	exploreHeroReroll() {
		this.event('explore_hero_reroll', {});
	}

	exploreHeroTap(params: {bot_id: number; bot_name: string; index: number}) {
		this.event('explore_hero_tap', params);
	}

	exploreUsecaseTapped(params: {
		bot_id: number;
		bot_name: string;
		usecase_text: string;
		index: number;
	}) {
		this.event('explore_usecase_tapped', params);
	}

	exploreRandomTapped() {
		this.event('explore_random_tapped', {});
	}

	chatContinue(params: {
		chat_id: number;
		bot_name: string;
		chat_type: string;
		access_level: string;
	}) {
		this.event('chat_continue', params);
	}

	notificationPopupShown() {
		this.event('notification_popup_shown', {});
	}

	notificationsAllowed() {
		this.event('notifications_allowed', {});
	}

	notificationsNotAllowed() {
		this.event('notifications_not_allowed', {});
	}

	notificationsSkipped() {
		this.event('notifications_skipped', {});
	}

	twoResponsesPopupAppear() {
		this.event('two_responses_popup_appear', {});
	}

	twoResponsesPopupOptionExpanded(params: {index: number}) {
		this.event('two_responses_popup_option_expanded', params);
	}

	twoResponsesPopupSelected(params: {index: number}) {
		this.event('two_responses_popup_selected', params);
	}

	private event(event: string, data: any, providersSkip = [] as string[]) {
		data.platform = 'web';
		if (!this.isOn) {
			console.log('event', event, data);
			return;
		}

		if (!checkIsAgreed()) {
			return;
		}

		this.providers.forEach((provider) => {
			if (providersSkip.includes(provider.providerId)) {
				return;
			}
			provider.event(event, data);
		});
	}
}

export const webEventsApi = new WebEventsApi([webAmpliApi]);
